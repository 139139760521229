import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-status-snack-bar',
  templateUrl: './status-snack-bar.component.html',
  styleUrls: ['./status-snack-bar.component.css']
})
export class StatusSnackBarComponent implements OnInit {

  constructor(public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  openSnackBar(message: string, status: SnackBarStatus) {
    const panelClass = status === SnackBarStatus.NEUTRAL ? '' : status === SnackBarStatus.SUCCESS ? 'success-snack-bar' : 'error-snack-bar';
    this.snackBar.open(message, undefined, {
      duration: 2500,
      panelClass: [panelClass]
    });
  }
}

export enum SnackBarStatus {
  NEUTRAL = 0,
  SUCCESS = 1,
  ERROR = 2
}
