import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-airsoft',
  templateUrl: './airsoft.component.html',
  styleUrls: ['./airsoft.component.css']
})
export class AirsoftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
