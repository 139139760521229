import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {MatDialog, MatTabChangeEvent} from '@angular/material';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<boolean>();
  public title = 'Team Foobar';

  constructor(private router: Router, public dialog: MatDialog) {
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.onDestroy.next(true);
  }

  public openAddMediaDialog(): void {
    const dialogRef = this.dialog.open(null, {
      data: {},
      panelClass: ['add-dialog']
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}
