import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatDialogModule,
  MatDividerModule, MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import {RouterModule, Routes} from '@angular/router';
import {StatusSnackBarComponent} from './status-snack-bar/status-snack-bar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SlideshowModule} from 'ng-simple-slideshow';
import {Configuration} from "jasmine-spec-reporter/built/configuration";
import {AirsoftComponent} from './airsoft/airsoft.component';
import {BaseComponent} from './base/base.component';
import {EventsComponent} from './airsoft/events/events.component';

export function config() {
  return new Configuration();
}

const appRoutes: Routes = [
  {
    path: "",
    component: BaseComponent,
    children: []
  },
  {
    path: "airsoft",
    component: AirsoftComponent,
    children: []
  },
  {
    path: "airsoft/events",
    component: EventsComponent,
    children: []
  }
];


@NgModule({
  declarations: [
    AppComponent,
    StatusSnackBarComponent,
    AirsoftComponent,
    BaseComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatExpansionModule,
    SlideshowModule,
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false}
    )
  ],
  providers: [StatusSnackBarComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
